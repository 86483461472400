import { StocksIcon } from '@flock/shared-ui'
import {
  Box,
  Button,
  Grid,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSnackbar } from '@flock/flock-component-library'
import { useMutation, useQuery } from '@apollo/client'
import { sessionStore } from '@flock/utils'
import Fade from 'react-reveal/Fade'
import {
  Core_LegalEntityUpdate,
  InvestorDashboardGetEquityInfoTimeseriesForInvestorDocument,
  InvestorDashboardGetInvestorUpdatesDocument,
  InvestorEditLegalEntityMutationDocument,
  InvestorGetLegalEntitiesByInvestorAccountUuidDocument,
  InvestorLegalEntityBankAccountDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { ContactPhone, Settings } from '@mui/icons-material'
import SectionLayout from '../../SharedComponents/SectionLayout'
import HomeMessageCenter from './HomeMessageCenter'
import PreferencesModal from '../Settings/FlexibleDistributions/PreferencesModal'
import ActiveOrdersCard from './ActiveOrderCard'
import PreferencesMobileDrawer from '../Settings/FlexibleDistributions/PreferencesMobileDrawer'
import HomeMessageCenterMobile from './HomeMessageCenterMobile'
import { useInvestorAccountContext } from '../../InvestorAccountContext'
import {
  INVESTMENT_PROJECTION_URL,
  RECENT_EARNINGS_CALL_URL,
} from '../../../constants'

type HomeSectionProps = {
  id: string
  messageCenterMobileOpen?: boolean
  setMessageCenterMobileOpen?: (open: boolean) => void
}

const StyledFade = styled(Fade)({
  '& .react-reveal': {
    display: 'inline-block',
  },
})

const HomeSection = (props: HomeSectionProps) => {
  const { id, messageCenterMobileOpen, setMessageCenterMobileOpen } = props
  const { notify } = useSnackbar()
  const [showOrders, setShowOrders] = useState(false)
  const [hasLegalEntityBankAccountData, setHasLegalEntityBankAccountData] =
    useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  let numColumns = 9
  if (isMobile) {
    numColumns = 4
  } else if (isTablet) {
    numColumns = 6
  } else {
    numColumns = 9
  }

  const {
    investorFirstName,
    selectedLegalEntity: legalEntityData,
    investorContextLoading,
    investorAccountUuidOverride,
    activeOrderStatuses,
  } = useInvestorAccountContext()

  const [updates, setUpdates] = useState<Core_LegalEntityUpdate[]>([])

  // as for why this is -1, ask rishov dutta
  const [totalCashTaken, setTotalCashTaken] = useState(-1)
  const [totalEquity, setTotalEquity] = useState(0)

  const legalEntity = legalEntityData

  const { loading: updatesLoading, data: updatesData } = useQuery(
    InvestorDashboardGetInvestorUpdatesDocument,
    {
      variables: {
        input: {
          investorUuid: investorAccountUuidOverride,
        },
      },
      skip: !investorAccountUuidOverride || updates.length > 0,
    }
  )

  useEffect(() => {
    if (updatesData) {
      setUpdates(
        updatesData?.getUpdates?.legalEntityUpdates as Core_LegalEntityUpdate[]
      )
    }
  }, [updatesData])

  const { loading: equityInfoTimeSeriesLoading, data: equityInfoTimeseries } =
    useQuery(InvestorDashboardGetEquityInfoTimeseriesForInvestorDocument, {
      variables: {
        input: {
          investorUuid: investorAccountUuidOverride,
          includeStaged: sessionStore.getItem('simulator') !== null,
        },
      },
      skip: !investorAccountUuidOverride || totalCashTaken !== -1,
    })

  const equityTimeseriesArray =
    equityInfoTimeseries?.getEquityInfoTimeseriesForInvestor
      ?.aggregateEquityInfo
  if (equityTimeseriesArray?.length! > 0 && totalCashTaken === -1) {
    const mostRecentData =
      equityTimeseriesArray![equityTimeseriesArray?.length! - 1]
    const redemptions = mostRecentData!.aggregateRedemptions! / 100
    const totalEquityValue = mostRecentData!.aggregateEquityValue! / 100

    setTotalCashTaken(-redemptions)
    setTotalEquity(totalEquityValue)
  }

  const { data: legalEntityBankAccountData, loading: bankAccountLoading } =
    useQuery(InvestorLegalEntityBankAccountDocument, {
      variables: {
        input: {
          legalEntityUuid: legalEntity.uuid,
        },
      },
      skip: !legalEntity || legalEntity.uuid === 'aggregate',
    })

  const bankAccount =
    legalEntityBankAccountData?.legalEntityBankAccount?.bankAccount

  useEffect(() => {
    if (bankAccount) {
      setHasLegalEntityBankAccountData?.(true)
    } else {
      setHasLegalEntityBankAccountData?.(false)
    }
  }, [bankAccount])

  const cashFlowAllotment = legalEntity?.cashFlowAllotment
  const cashFlowSelection = legalEntity?.cashFlowSelection
  const legalEntityUuid = legalEntity?.uuid

  const [editLegalEntity] = useMutation(InvestorEditLegalEntityMutationDocument)

  const [showPreferencesModal, setShowPreferencesModal] = useState(false)
  const [showPreferencesMobileDrawer, setShowPreferencesMobileDrawer] =
    useState(false)
  const confirmCashFlow = async (newCashFlowPercentage: number) => {
    let cashDistributionEnabled = false
    if (newCashFlowPercentage > 0) {
      // Enable cash distribution
      cashDistributionEnabled = true
    }

    try {
      await editLegalEntity({
        variables: {
          editLegalEntityInput: {
            uuid: legalEntity?.uuid || legalEntityUuid,
            cashDistributionEnabled,
            cashFlowSelection: newCashFlowPercentage,
          },
        },
        refetchQueries: [InvestorGetLegalEntitiesByInvestorAccountUuidDocument],
      })

      notify('Successfully updated cash flow preference.', 'success')
    } catch (e) {
      notify(
        'An error has occurred and our team has been notified. Please refresh and try again or contact our team for assistance.',
        'error'
      )
    }
  }

  const anyLoading =
    investorContextLoading ||
    updatesLoading ||
    equityInfoTimeSeriesLoading ||
    bankAccountLoading

  useEffect(() => {
    if (activeOrderStatuses.length > 0 && !anyLoading) {
      setTimeout(() => {
        setShowOrders(true)
      }, 775)
    }
  }, [anyLoading, activeOrderStatuses.length])

  return (
    <>
      <SectionLayout name="home-section" columns={numColumns} id={id}>
        <Grid item xs={12}>
          {/* <Grid container columns={isMobile ? 4 : 9} spacing={isMobile ? 2 : 4}> */}
          <Grid container columns={numColumns} spacing={isMobile ? 2 : 4}>
            <Grid item xs={numColumns}>
              <Typography
                variant="h3"
                sx={{ '& > .react-reveal': { display: 'inline-block' } }}
              >
                <StyledFade duration={500} distance="16px" bottom>
                  <>Welcome, {investorFirstName}.</>
                </StyledFade>
              </Typography>
            </Grid>

            {!isMobile && (
              <Grid item sm={numColumns} md={numColumns}>
                <Grid
                  container
                  columns={6}
                  spacing={4}
                  sx={{ flexWrap: 'nowrap!important' }}
                >
                  <Grid
                    item
                    sm={6}
                    md={!anyLoading && activeOrderStatuses.length > 0 ? 8 : 12}
                    sx={{ transition: 'all 750ms ease-in-out' }}
                  >
                    <HomeMessageCenter
                      messages={updates}
                      loading={updatesLoading}
                    />
                  </Grid>
                  {!isTablet &&
                    !anyLoading &&
                    activeOrderStatuses.length > 0 &&
                    showOrders && (
                      <Grid item xs={4}>
                        <Fade duration={500} distance="16px" right>
                          <ActiveOrdersCard />
                        </Fade>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            )}

            {isTablet ? (
              <Grid xs={numColumns} sm={numColumns} item>
                <Box
                  display="flex"
                  flexDirection="row"
                  gap="8px"
                  flexWrap="wrap"
                >
                  <Button
                    variant="secondary"
                    color="primary"
                    onClick={() => window.open(RECENT_EARNINGS_CALL_URL)}
                    sx={{
                      height: '38px',
                      alignItems: 'center',
                      padding: '12px 16px',
                      width: 'auto',
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      gap="8px"
                      alignItems="center"
                    >
                      <ContactPhone width="24px" height="24px" />
                      <Typography variant="cta" fontSize="16px">
                        Watch Earnings Call
                      </Typography>
                    </Box>
                  </Button>
                  <Button
                    variant="secondary"
                    color="primary"
                    onClick={() => window.open(INVESTMENT_PROJECTION_URL)}
                    sx={{
                      height: '38px',
                      alignItems: 'center',
                      padding: '12px 16px',
                      width: 'auto',
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      gap="8px"
                      alignItems="center"
                    >
                      <StocksIcon />
                      <Typography variant="cta" fontSize="16px">
                        Investment Projections
                      </Typography>
                    </Box>
                  </Button>
                  {hasLegalEntityBankAccountData && (
                    <Button
                      variant="secondary"
                      color="primary"
                      onClick={() =>
                        isMobile
                          ? setShowPreferencesMobileDrawer(true)
                          : setShowPreferencesModal(true)
                      }
                      sx={{
                        height: '38px',
                        alignItems: 'center',
                        padding: '12px 16px',
                        width: 'auto',
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        gap="8px"
                        alignItems="center"
                      >
                        <Settings width="24px" height="24px" />
                        <Typography variant="cta" fontSize="16px">
                          Edit Cash Flow Settings
                        </Typography>
                      </Box>
                    </Button>
                  )}
                </Box>
              </Grid>
            ) : (
              <Grid xs={9} item>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  gap="8px"
                  alignSelf="stretch"
                >
                  <Button
                    variant="secondary"
                    color="primary"
                    onClick={() => window.open(RECENT_EARNINGS_CALL_URL)}
                    sx={{
                      height: '38px',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      gap="8px"
                      alignItems="center"
                    >
                      <ContactPhone width="24px" height="24px" />
                      <Typography variant="cta" fontSize="16px">
                        Watch Earnings Call
                      </Typography>
                    </Box>
                  </Button>
                  <Button
                    variant="secondary"
                    color="primary"
                    onClick={() => window.open(INVESTMENT_PROJECTION_URL)}
                    sx={{
                      height: '38px',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      gap="8px"
                      alignItems="center"
                    >
                      <StocksIcon />
                      <Typography variant="cta" fontSize="16px">
                        Investment Projections
                      </Typography>
                    </Box>
                  </Button>
                  {hasLegalEntityBankAccountData && (
                    <Button
                      variant="secondary"
                      color="primary"
                      onClick={() => setShowPreferencesModal(true)}
                      sx={{
                        height: '38px',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        gap="8px"
                        alignItems="center"
                      >
                        <Settings width="24px" height="24px" />
                        <Typography variant="cta" fontSize="16px">
                          Edit Cash Flow Settings
                        </Typography>
                      </Box>
                    </Button>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
        {showPreferencesMobileDrawer && (
          <PreferencesMobileDrawer
            open={showPreferencesMobileDrawer}
            cashFlowAllotment={cashFlowAllotment || 0}
            cashFlowSelection={cashFlowSelection || 0}
            totalEquity={totalEquity}
            onSubmit={confirmCashFlow}
            onClose={() => setShowPreferencesMobileDrawer(false)}
          />
        )}

        <HomeMessageCenterMobile
          open={messageCenterMobileOpen || false}
          messages={updates}
          onClose={() =>
            setMessageCenterMobileOpen
              ? setMessageCenterMobileOpen(false)
              : undefined
          }
        />
      </SectionLayout>
      {showPreferencesModal && (
        <PreferencesModal
          open={showPreferencesModal}
          cashFlowAllotment={cashFlowAllotment || 0}
          cashFlowSelection={cashFlowSelection || 0}
          totalEquity={totalEquity}
          onSubmit={confirmCashFlow}
          onClose={() => setShowPreferencesModal(false)}
        />
      )}
    </>
  )
}

HomeSection.defaultProps = {
  messageCenterMobileOpen: undefined,
  setMessageCenterMobileOpen: undefined,
}

export default HomeSection
