import { Core_GetWaterfallDataResponse } from '@flock/flock-gql-server/src/__generated__/graphql'
import { useStaticQuery, graphql } from 'gatsby'
import { useEffect, useState } from 'react'
import { sortBy } from 'lodash'
import {
  FundsFromOperationsColumn,
  FundsFromOperationsTableData,
} from './fundsFromOperationsPageTypes'
import { formatDollarsAndCentsInvestor } from '../../utils/formatting'

const useFundsFromOperationsPage = (): FundsFromOperationsTableData => {
  const [quarters, setQuarters] = useState<string[]>([])
  const [selectedQuarter, setSelectedQuarter] = useState('')
  const [ffoData, setFfoData] = useState<Core_GetWaterfallDataResponse>()
  const [selectedQuarterFfoData, setSelectedQuarterFfoData] =
    useState<FundsFromOperationsColumn>({} as FundsFromOperationsColumn)
  // data
  const {
    getWaterfallData: waterfallData,
  }: {
    getWaterfallData: Core_GetWaterfallDataResponse
  } = useStaticQuery(graphql`
    query InvestorFundFromOperationsPage {
      getWaterfallData {
        fundsFromOperationsRows {
          amountCents
          quarterString
          rowName
        }
      }
    }
  `)

  useEffect(() => {
    setFfoData(waterfallData)
    const ffoQuarters = Array.from(
      new Set(
        waterfallData.fundsFromOperationsRows.map((row) => row.quarterString)
      )
    )

    const uniqueQuarters = Array.from(new Set(ffoQuarters))
    const sortedUniqueQuarters = sortBy(
      uniqueQuarters,
      (q: string) => `${q.split(' ')[1]} ${q.split(' ')[0]}`
    ) as string[]

    setQuarters(sortedUniqueQuarters)
    setSelectedQuarter(sortedUniqueQuarters[sortedUniqueQuarters.length - 1])
  }, [waterfallData])

  useEffect(() => {
    const currentQuarterData = ffoData?.fundsFromOperationsRows?.filter(
      (row) => row.quarterString === selectedQuarter
    )

    const totalPortfolioIncome =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Total portfolio income'
      )?.amountCents || 0) / 100

    const totalRentalIncome =
      (currentQuarterData?.find((row) => row.rowName === 'Total rental income')
        ?.amountCents || 0) / 100

    const rentalIncome =
      (currentQuarterData?.find((row) => row.rowName === 'Rental income')
        ?.amountCents || 0) / 100

    const belowMarketLeaseAdjustments =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Below market lease adjustments'
      )?.amountCents || 0) / 100

    const badDebtRent =
      (currentQuarterData?.find((row) => row.rowName === 'Bad debt - rent')
        ?.amountCents || 0) / 100

    const rentalAssistanceIncome =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Rental assistance income'
      )?.amountCents || 0) / 100

    const totalOtherIncome =
      (currentQuarterData?.find((row) => row.rowName === 'Total other income')
        ?.amountCents || 0) / 100

    const petRent =
      (currentQuarterData?.find((row) => row.rowName === 'Pet rent income')
        ?.amountCents || 0) / 100

    const lateFees =
      (currentQuarterData?.find((row) => row.rowName === 'Late fees')
        ?.amountCents || 0) / 100

    const applicationAndAdministrativeFees =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Application and administrative fees'
      )?.amountCents || 0) / 100

    const insuranceClaimIncome =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Insurance claim income'
      )?.amountCents || 0) / 100

    const processingFees =
      (currentQuarterData?.find((row) => row.rowName === 'LP processing fees')
        ?.amountCents || 0) / 100

    const damageFees =
      (currentQuarterData?.find((row) => row.rowName === 'Damage fees')
        ?.amountCents || 0) / 100

    const securityDepositForfeiture =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Security deposit forfeiture'
      )?.amountCents || 0) / 100

    const badDebtOtherIncome =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Bad debt - other income'
      )?.amountCents || 0) / 100

    const otherMiscellaneousIncome =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Other miscellaneous income'
      )?.amountCents || 0) / 100

    const totalPortfolioExpenses =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Total portfolio expenses'
      )?.amountCents || 0) / 100

    const totalOperatingExpenses =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Total operating expense'
      )?.amountCents || 0) / 100

    const propertyTax =
      (currentQuarterData?.find((row) => row.rowName === 'Property tax')
        ?.amountCents || 0) / 100

    const propertyInsurance =
      (currentQuarterData?.find((row) => row.rowName === 'Property insurance')
        ?.amountCents || 0) / 100

    const propertyManagementFee =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Property management fee'
      )?.amountCents || 0) / 100

    const hoaDuesAndFines =
      (currentQuarterData?.find((row) => row.rowName === 'HOA dues')
        ?.amountCents || 0) / 100

    const leasingFees =
      (currentQuarterData?.find((row) => row.rowName === 'Leasing fees')
        ?.amountCents || 0) / 100

    const leasingAmortization =
      (currentQuarterData?.find(
        (row) =>
          row.rowName === 'Leasing commission amortization - rogers leasing fee'
      )?.amountCents || 0) / 100

    const repairsAndMaintenance =
      (currentQuarterData
        ?.filter((row) => row.rowName.startsWith('Repairs and maint'))
        .reduce((sum, row) => row.amountCents + sum, 0) || 0) / 100

    const turnExpense =
      (currentQuarterData
        ?.filter((row) => row.rowName.startsWith('Turn expense'))
        .reduce((sum, row) => row.amountCents + sum, 0) || 0) / 100

    const utilities =
      (currentQuarterData?.find((row) => row.rowName === 'Utilities')
        ?.amountCents || 0) / 100

    const propertyLicensesAndPermits =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Property licenses and permits'
      )?.amountCents || 0) / 100

    const evictionExpense =
      (currentQuarterData?.find((row) => row.rowName === 'Eviction expense')
        ?.amountCents || 0) / 100

    const otherExpense =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Other miscellaneous expense'
      )?.amountCents || 0) / 100

    const totalAdministrativeFees =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Total administrative expense'
      )?.amountCents || 0) / 100

    const bankCharges =
      (currentQuarterData?.find((row) => row.rowName === 'Bank charges')
        ?.amountCents || 0) / 100

    const thirdPartyFundAdminExpense =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Third-party fund administration expense'
      )?.amountCents || 0) / 100

    const thirdPartyTaxAndAuditExpense =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Third party tax and audit expense'
      )?.amountCents || 0) / 100

    const thirdPartyInsuranceExpense =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Third-party fund insurance'
      )?.amountCents || 0) / 100

    const softwareFees =
      (currentQuarterData?.find((row) => row.rowName === 'Software fees')
        ?.amountCents || 0) / 100

    const flockAssetManagementFee =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Flock asset management fees'
      )?.amountCents || 0) / 100

    const fundEntityLevelTaxes =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Fund entity-level taxes'
      )?.amountCents || 0) / 100

    const fundServicingExpense =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Fund servicing expense'
      )?.amountCents || 0) / 100

    const totalInterestExpense =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Total interest expense'
      )?.amountCents || 0) / 100

    const leasingFeeAmortization =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Leasing fee amortization'
      )?.amountCents || 0) / 100

    const loanFeesAmortization =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Loan fees amortization'
      )?.amountCents || 0) / 100

    const interestExpenseFirstBank =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Interest expense - FirstBank'
      )?.amountCents || 0) / 100

    const interestExpenseSunflower =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Interest expense - Sunflower'
      )?.amountCents || 0) / 100

    const interestExpenseLendingOne =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Interest expense - LendingOne'
      )?.amountCents || 0) / 100

    const interestExpenseEvergreen =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Interest expense - Evergreen'
      )?.amountCents || 0) / 100

    const interestExpenseIntercompany =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Interest expense - Intercompany'
      )?.amountCents || 0) / 100

    const earlyRedemptionFees =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Early redemption fees'
      )?.amountCents || 0) / 100
    // replaces the early redemption fee terminology for q4 2024 and beyond
    const topUpPenaltyFees =
      (currentQuarterData?.find((row) => row.rowName === 'Top-up penalty')
        ?.amountCents || 0) / 100

    const portfolioNetIncome =
      (currentQuarterData?.find(
        (row) => row.rowName === 'Total portfolio net income'
      )?.amountCents || 0) / 100

    const nonCashItems =
      loanFeesAmortization +
      leasingFeeAmortization +
      leasingAmortization -
      processingFees -
      earlyRedemptionFees -
      topUpPenaltyFees

    const fundsFromOperations = portfolioNetIncome + nonCashItems

    setSelectedQuarterFfoData({
      quarterString: selectedQuarter,
      totalPortfolioIncome: totalPortfolioIncome
        ? formatDollarsAndCentsInvestor(totalPortfolioIncome, false, false)
        : '-',
      totalRentalIncome: totalRentalIncome
        ? formatDollarsAndCentsInvestor(totalRentalIncome, false, false)
        : '-',
      rentalIncome: rentalIncome
        ? formatDollarsAndCentsInvestor(rentalIncome, false, false)
        : '-',
      belowMarketLeaseAdjustments: belowMarketLeaseAdjustments
        ? formatDollarsAndCentsInvestor(
            belowMarketLeaseAdjustments,
            false,
            false
          )
        : '-',
      badDebtRent: badDebtRent
        ? formatDollarsAndCentsInvestor(badDebtRent, false, false)
        : '-',
      rentalAssistanceIncome: rentalAssistanceIncome
        ? formatDollarsAndCentsInvestor(rentalAssistanceIncome, false, false)
        : '-',
      totalOtherIncome: totalOtherIncome
        ? formatDollarsAndCentsInvestor(totalOtherIncome, false, false)
        : '-',
      petRent: petRent
        ? formatDollarsAndCentsInvestor(petRent, false, false)
        : '-',
      lateFees: lateFees
        ? formatDollarsAndCentsInvestor(lateFees, false, false)
        : '-',
      applicationAndAdministrativeFees: applicationAndAdministrativeFees
        ? formatDollarsAndCentsInvestor(
            applicationAndAdministrativeFees,
            false,
            false
          )
        : '-',
      insuranceClaimIncome: insuranceClaimIncome
        ? formatDollarsAndCentsInvestor(insuranceClaimIncome, false, false)
        : '-',
      processingFees: processingFees
        ? formatDollarsAndCentsInvestor(processingFees, false, false)
        : '-',
      damageFees: damageFees
        ? formatDollarsAndCentsInvestor(damageFees, false, false)
        : '-',
      securityDepositForfeiture: securityDepositForfeiture
        ? formatDollarsAndCentsInvestor(securityDepositForfeiture, false, false)
        : '-',
      badDebtOtherIncome: badDebtOtherIncome
        ? formatDollarsAndCentsInvestor(badDebtOtherIncome, false, false)
        : '-',
      otherMiscellaneousIncome: otherMiscellaneousIncome
        ? formatDollarsAndCentsInvestor(otherMiscellaneousIncome, false, false)
        : '-',
      totalPortfolioExpenses: totalPortfolioExpenses
        ? formatDollarsAndCentsInvestor(totalPortfolioExpenses, false, false)
        : '-',
      totalOperatingExpenses: totalOperatingExpenses
        ? formatDollarsAndCentsInvestor(totalOperatingExpenses, false, false)
        : '-',
      propertyTax: propertyTax
        ? formatDollarsAndCentsInvestor(propertyTax, false, false)
        : '-',
      propertyInsurance: propertyInsurance
        ? formatDollarsAndCentsInvestor(propertyInsurance, false, false)
        : '-',
      propertyManagementFee: propertyManagementFee
        ? formatDollarsAndCentsInvestor(propertyManagementFee, false, false)
        : '-',
      hoaDuesAndFines: hoaDuesAndFines
        ? formatDollarsAndCentsInvestor(hoaDuesAndFines, false, false)
        : '-',
      leasingFees: leasingFees
        ? formatDollarsAndCentsInvestor(leasingFees, false, false)
        : '-',
      leasingAmortization: leasingAmortization
        ? formatDollarsAndCentsInvestor(leasingAmortization, false, false)
        : '-',
      repairsAndMaintenance: repairsAndMaintenance
        ? formatDollarsAndCentsInvestor(repairsAndMaintenance, false, false)
        : '-',
      turnExpense: turnExpense
        ? formatDollarsAndCentsInvestor(turnExpense, false, false)
        : '-',
      utilities: utilities
        ? formatDollarsAndCentsInvestor(utilities, false, false)
        : '-',
      propertyLicensesAndPermits: propertyLicensesAndPermits
        ? formatDollarsAndCentsInvestor(
            propertyLicensesAndPermits,
            false,
            false
          )
        : '-',
      evictionExpense: evictionExpense
        ? formatDollarsAndCentsInvestor(evictionExpense, false, false)
        : '-',
      otherExpense: otherExpense
        ? formatDollarsAndCentsInvestor(otherExpense, false, false)
        : '-',
      totalAdministrativeFees: totalAdministrativeFees
        ? formatDollarsAndCentsInvestor(totalAdministrativeFees, false, false)
        : '-',
      bankCharges: bankCharges
        ? formatDollarsAndCentsInvestor(bankCharges, false, false)
        : '-',
      thirdPartyFundAdminExpense: thirdPartyFundAdminExpense
        ? formatDollarsAndCentsInvestor(
            thirdPartyFundAdminExpense,
            false,
            false
          )
        : '-',
      thirdPartyTaxAndAuditExpense: thirdPartyTaxAndAuditExpense
        ? formatDollarsAndCentsInvestor(
            thirdPartyTaxAndAuditExpense,
            false,
            false
          )
        : '-',
      thirdPartyInsuranceExpense: thirdPartyInsuranceExpense
        ? formatDollarsAndCentsInvestor(
            thirdPartyInsuranceExpense,
            false,
            false
          )
        : '-',
      softwareFees: softwareFees
        ? formatDollarsAndCentsInvestor(softwareFees, false, false)
        : '-',
      flockAssetManagementFee: flockAssetManagementFee
        ? formatDollarsAndCentsInvestor(flockAssetManagementFee, false, false)
        : '-',
      fundEntityLevelTaxes: fundEntityLevelTaxes
        ? formatDollarsAndCentsInvestor(fundEntityLevelTaxes, false, false)
        : '-',
      fundServicingExpense: fundServicingExpense
        ? formatDollarsAndCentsInvestor(fundServicingExpense, false, false)
        : '-',
      totalInterestExpense: totalInterestExpense
        ? formatDollarsAndCentsInvestor(totalInterestExpense, false, false)
        : '-',
      leasingFeeAmortization: leasingFeeAmortization
        ? formatDollarsAndCentsInvestor(leasingFeeAmortization, false, false)
        : '-',
      loanFeesAmortization: loanFeesAmortization
        ? formatDollarsAndCentsInvestor(loanFeesAmortization, false, false)
        : '-',
      interestExpenseFirstBank: interestExpenseFirstBank
        ? formatDollarsAndCentsInvestor(interestExpenseFirstBank, false, false)
        : '-',
      interestExpenseSunflower: interestExpenseSunflower
        ? formatDollarsAndCentsInvestor(interestExpenseSunflower, false, false)
        : '-',
      interestExpenseLendingOne: interestExpenseLendingOne
        ? formatDollarsAndCentsInvestor(interestExpenseLendingOne, false, false)
        : '-',
      interestExpenseEvergreen: interestExpenseEvergreen
        ? formatDollarsAndCentsInvestor(interestExpenseEvergreen, false, false)
        : '-',
      interestExpenseIntercompany: interestExpenseIntercompany
        ? formatDollarsAndCentsInvestor(
            interestExpenseIntercompany,
            false,
            false
          )
        : '-',
      earlyRedemptionFees: earlyRedemptionFees
        ? formatDollarsAndCentsInvestor(earlyRedemptionFees, false, false)
        : '-',
      topUpPenaltyFees: topUpPenaltyFees
        ? formatDollarsAndCentsInvestor(topUpPenaltyFees, false, false)
        : '-',
      leasingFeeAmortizationExpense: leasingFeeAmortization
        ? formatDollarsAndCentsInvestor(-leasingFeeAmortization, false, false)
        : '-',
      portfolioNetIncome: portfolioNetIncome
        ? formatDollarsAndCentsInvestor(portfolioNetIncome, false, false)
        : '-',
      nonCashItems: nonCashItems
        ? formatDollarsAndCentsInvestor(nonCashItems, false, false)
        : '-',
      fundsFromOperations: fundsFromOperations
        ? formatDollarsAndCentsInvestor(fundsFromOperations, false, false)
        : '-',
    })
  }, [ffoData, selectedQuarter])

  return {
    selectedQuarterFfoData,
    selectedQuarter,
    quarters,
    setSelectedQuarter,
  }
}

export default useFundsFromOperationsPage
